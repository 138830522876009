<template>
  <header>
    <img class="logo" :src="require('@/assets/pool-party-square.svg')" />
  </header>
  <router-view></router-view>
</template>

<script></script>

<style>
  html {
    height: 100%;
  }
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  #app {
    font-family: Arial;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  header {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  header .logo {
    height: 33vw;
    max-height: 30vh;
  }

  header .profile-pic {
    position: fixed;
    height: 10vw;
    max-height: 10vh;
    left:5vw;
    border-radius: 50%;
  }

  header .log-out {
    position: fixed;
    right: 5vw;
    text-decoration: none;
    color: #000;
    text-align:left;
    width: 2em;
  }
</style>
