import { createWebHistory, createRouter } from "vue-router";

var routes = [
  {
    path: '/',
    name: 'PoolParty',
    component: function() { return import ('../views/login-prompt.vue') }
  },
	{
    path: '/oauth/google',
    name: 'GoogleOAuth',
    component: function() { return import ('../components/oauth-google.vue') }
  },
  {
    path: '/logout',
    component: function() { return import ('../components/log-out.vue') }
  },
	{
		path: '/:pathMatch(.*)*',
		redirect: "/"
	}
];

var router = createRouter({
  history: createWebHistory(), routes
});

export default router;